import * as Yup from 'yup';
//TODO adjust to match validation requirements
export const professionalValidationBeneficiary = Yup.object().shape({
  ape: Yup.string().required('Ce champ est requis').default(''),
  contract: Yup.string().required('Ce champ est requis').default(''),
  isWorkArrest: Yup.boolean(),
  dateWorkArrest: Yup.string().when('isWorkArrest', {
    is: true,
    then: Yup.string().required('Ce champ est requis'),
    otherwise: Yup.string().notRequired(),
  }),
  typeWorkArrest: Yup.string().when('isWorkArrest', {
    is: true,
    then: Yup.string().required('Ce champ est requis'),
    otherwise: Yup.string().notRequired(),
  }),
  formation: Yup.string().required('Ce champ est requis').default(''),
  job: Yup.string().required('Ce champ est requis').default(''),
  isBOE: Yup.boolean(),
  statusBOE: Yup.string().when('isBOE', {
    is: true,
    then: Yup.string().required('Ce champ est requis'),
    otherwise: Yup.string().notRequired(),
  }),
  language: Yup.string().required('Ce champ est requis').default(''),
  languageLevel: Yup.string().required('Ce champ est requis').default(''),
  levelSchool: Yup.string().required('Ce champ est requis').default(''),
  status: Yup.string().required('Ce champ est requis').default(''),
  departmentLocation: Yup.string().required('Ce champ est requis').default(''),
});

export const professionalValidationConsultant = Yup.object().shape({
  organismTmp: Yup.string()
    .min(1, 'Ce champ doit faire au minimum 1 caractère')
    .max(100, 'Ce champ ne peut pas faire plus de 100 caractères')
    .matches(/\w+/, {
      excludeEmptyString: true,
      message: 'Ce champ ne peut pas contenir que des espaces vides',
    })
    .required('Ce champ est requis')
    .default(''),
  postOccupied: Yup.string()
    .min(1, 'Ce champ doit faire au minimum 1 caractère')
    .max(100, 'Ce champ ne peut pas faire plus de 100 caractères')
    .matches(/\w+/, {
      excludeEmptyString: true,
      message: 'Ce champ ne peut pas contenir que des espaces vides',
    })
    .required('Ce champ est requis')
    .default(''),
});
