import { Fragment, useEffect, useMemo, useState } from "react";
import {
  BasicProfileForm,
  CredentialsForm,
  CodeVerificationForm,
  InvitedCredentialsForm,
} from "./Components";
import { CreateAccount, TAccountCreateData } from "../../Types";
import TabsUnstyled from "@mui/base/TabsUnstyled";
import { TabPanel } from "../../Theme/MaterialStyledComponents";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Button, Hidden, Theme } from "@mui/material";
import { TUserRole, ITypeRouteParams } from "../../Types";
import {
  updateLoginState,
  resetLoginState,
  updateUserState,
  resetUserState,
} from "../../Stores";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useStateMachine } from "little-state-machine";
import { useError } from "../../Hooks";
import { CarouselComponent, Copyright } from "../../Components";
import Logo from "../../Assets/logo.png";
import APIAxios, { APIRoutes } from "../../API/api.axios";
import { TopColorIndicator } from "../../Components/Organisms/login-card.component";

const useStyles = makeStyles((theme: Theme) => ({
  shadowed: {
    padding: theme.spacing(6, 5),
    borderRadius: `0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px`,
    position: "relative",
    zIndex: 1,
    boxShadow: theme.customShadows.shadow1,
  },
  containerHeight: {
    height: `calc(100vh - ${theme.customShapes.footerHeight})`,
  },
  logo: {
    maxWidth: "150px",
  },
  [theme.breakpoints.down("md")]: {
    container: {
      padding: "2rem",
    },
  },
  [theme.breakpoints.down("sm")]: {
    container: {
      padding: "1rem",
    },
    shadowed: {
      padding: theme.spacing(3, 2),
    },
    logo: {
      maxWidth: "100px",
    },
  },
}));

export const Signup = () => {
  const { type } = useParams<ITypeRouteParams>();
  const { addError } = useError();
  const [searchParams] = useSearchParams();
  const [currentStep, setCurrentStep] = useState<number>(1); // TODO change this number by an enum
  const {
    state: { user },
    actions,
  } = useStateMachine({
    updateLoginState,
    resetLoginState,
    updateUserState,
    resetUserState,
  });
  const classes = useStyles();
  const navigate = useNavigate();

  const invitedAuthorization = useMemo(() => {
    return searchParams.get("invitToken");
  }, [searchParams]);

  useEffect(() => {
    if (!invitedAuthorization && type === "beneficiary") {
      addError({
        message:
          "Veuillez faire une demande d'invitation auprès d'un de vos consultants pour accéder à la plateforme.",
      });
      navigate("/select-role");
    } else if (invitedAuthorization) {
      APIAxios({
        ...APIRoutes.POSTVerifySignupToken(invitedAuthorization),
      }).catch((err) => {
        addError({
          message:
            "Veuillez refaire une demande de création de compte auprès de votre consultant.",
        });
        navigate("/select-role");
      });
    }
  }, [invitedAuthorization, type, addError, navigate]);

  useEffect(() => {
    if (user.isAuth) {
      if (user.consultant?.isLocked) {
        actions.resetLoginState();
        actions.resetUserState();
        localStorage.removeItem("accessToken");
        navigate("/select-role");
        addError({ code: "account-locked" });
      } else {
        localStorage.setItem("shouldCompleteProfile", "true");
        if (user.organism) {
          navigate("/organism/consultants");
        } else {
          navigate(`/${type}/dashboard`);
        }
      }
    }
  }, [user, navigate, type, addError, actions]);

  const handleSignup = async (data: Partial<TAccountCreateData>) => {
    const createAcc: Partial<CreateAccount> = {
      gender: data.gender,
      password: data.password,
      city: data.city,
      name: data.name,
      firstName: data.firstName,
      lastName: data.lastName,
      phoneNumber: data.phoneNumber,
      email: data.email,
      postcode: data.postcode,
      address: data.address,
      organismTmp: data.organismTmp,
      consent: data.consent || undefined,
    };

    if (type === "consultant") {
      try {
        const res = await APIAxios({
          ...APIRoutes.POSTSignInConsultant(),
          data: createAcc,
        });
        if (res.data) {
          localStorage.setItem("accessToken", res.data.accessToken);
          actions.updateUserState({
            consultant: res.data.consultant,
            id: res.data.organism,
            isAuth: true,
          });
        }
      } catch (error: any) {
        switch (error.response.status) {
          case 409:
            addError({ message: "Cet e-mail est déjà associé à un compte" });
            break;
          default:
            addError({
              message: "Une erreur est survenue. Veuillez réessayer plus tard.",
            });
            break;
        }
      }
    } else if (type === "beneficiary") {
      try {
        const res = await APIAxios({
          ...APIRoutes.POSTSignInBeneficiaries(),
          data: createAcc,
        });
        if (res.data) {
          localStorage.setItem("accessToken", res.data.accessToken);
          actions.updateUserState({
            beneficiary: res.data.beneficiary,
            id: res.data.organism,
            isAuth: true,
          });
        }
      } catch (error: any) {
        switch (error.response.status) {
          case 409:
            addError({ message: "Cet e-mail est déjà associé à un compte" });
            break;
          default:
            addError({
              message: "Une erreur est survenue. Veuillez réessayer plus tard.",
            });
            break;
        }
      }
    } else {
      try {
        const res = await APIAxios({
          ...APIRoutes.POSTSignInOrganism(),
          data: createAcc,
        });
        if (res.data) {
          localStorage.setItem("accessToken", res.data.accessToken);
          actions.updateUserState({
            organism: res.data.organism,
            id: res.data.organism,
            isAuth: true,
          });
        }
      } catch (error: any) {
        switch (error.response.status) {
          case 409:
            addError({ message: "Cet e-mail est déjà associé à un compte" });
            break;
          default:
            addError({
              message: "Une erreur est survenue. Veuillez réessayer plus tard.",
            });
            break;
        }
      }
    }
  };

  return (
    <>
      <Grid
        container
        direction="column"
        justifyContent="center"
        margin="auto"
        maxWidth={800}
        height="100vh"
        className={`${classes.container} ${classes.containerHeight}`}
        flexWrap="nowrap"
        gap={{ xs: 3, sm: 5 }}
        position="relative"
      >
        <Grid item container justifyContent="center">
          <img src={Logo} alt="" className={classes.logo} />
        </Grid>
        <Grid item container justifyContent="center" width="100%">
          <TabsUnstyled value={type} style={{ width: "100%" }}>
            <TabPanel
              value={"beneficiary" as TUserRole}
              className={classes.shadowed}
            >
              <TopColorIndicator type={type || ""} />
              <Grid container direction="row">
                <Hidden smDown>
                  <Grid item xs={6} container>
                    <CarouselComponent />
                    <Typography variant="subtitle1" component="div">
                      {`Vous avez déjà un compte ?`}
                      <Button
                        variant="text"
                        color="secondary"
                        onClick={() => navigate("/login/beneficiary")}
                      >
                        Se connecter
                      </Button>
                    </Typography>
                  </Grid>
                </Hidden>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  container
                  direction="column"
                  rowGap={3}
                >
                  <Typography variant="h2">Créer un compte</Typography>
                  {currentStep === 1 ? (
                    <BasicProfileForm setCurrentStep={setCurrentStep} />
                  ) : (
                    <CredentialsForm
                      setCurrentStep={setCurrentStep}
                      handleSignup={handleSignup}
                      isBeneficiaryInvited={!!invitedAuthorization}
                    />
                  )}
                  <Hidden smUp>
                    <Typography variant="subtitle1" component="div">
                      {`Vous avez déjà un compte ?`}
                      <Button
                        variant="text"
                        color="secondary"
                        onClick={() => navigate("/login/beneficiary")}
                      >
                        Se connecter
                      </Button>
                    </Typography>
                  </Hidden>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel
              value={"organism" as TUserRole}
              className={classes.shadowed}
            >
              <TopColorIndicator type={type || ""} />
              <Grid container direction="row">
                <Hidden smDown>
                  <Grid item xs={6} container>
                    <CarouselComponent />
                    <Typography variant="subtitle1" component="div">
                      {`Vous avez déjà un compte ?`}
                      <Button
                        variant="text"
                        color="secondary"
                        onClick={() => navigate("/login/organism")}
                      >
                        Se connecter
                      </Button>
                    </Typography>
                  </Grid>
                </Hidden>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  container
                  direction="column"
                  rowGap={3}
                >
                  <Typography variant="h2">Créer un compte</Typography>
                  {currentStep === 1 ? (
                    <BasicProfileForm setCurrentStep={setCurrentStep} />
                  ) : (
                    <CredentialsForm
                      setCurrentStep={setCurrentStep}
                      handleSignup={handleSignup}
                    />
                  )}
                  <Hidden smUp>
                    <Typography variant="subtitle1" component="div">
                      {`Vous avez déjà un compte ?`}
                      <Button
                        variant="text"
                        color="secondary"
                        onClick={() => navigate("/login/organism")}
                      >
                        Se connecter
                      </Button>
                    </Typography>
                  </Hidden>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel
              value={"consultant" as TUserRole}
              className={classes.shadowed}
            >
              <TopColorIndicator type={type || ""} />
              <Grid container direction="row" justifyContent="space-between">
                <Hidden smDown>
                  <Grid item xs={6} container>
                    <CarouselComponent />
                    <Typography variant="subtitle1" component="div">
                      {`Vous avez déjà un compte ?`}
                      <Button
                        variant="text"
                        color="secondary"
                        onClick={() => navigate("/login/consultant")}
                      >
                        Se connecter
                      </Button>
                    </Typography>
                  </Grid>
                </Hidden>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  container
                  direction="column"
                  rowGap={3}
                >
                  <Typography variant="h2">Créer un compte</Typography>
                  {!invitedAuthorization && (
                    <Fragment>
                      {currentStep === 1 ? (
                        <BasicProfileForm setCurrentStep={setCurrentStep} />
                      ) : (
                        <CredentialsForm
                          setCurrentStep={setCurrentStep}
                          handleSignup={handleSignup}
                        />
                      )}
                    </Fragment>
                  )}
                  {!!invitedAuthorization && (
                    <Fragment>
                      {currentStep === 1 ? (
                        <CodeVerificationForm setCurrentStep={setCurrentStep} />
                      ) : (
                        <InvitedCredentialsForm
                          setCurrentStep={setCurrentStep}
                          handleSignup={handleSignup}
                        />
                      )}
                    </Fragment>
                  )}
                  <Hidden smUp>
                    <Typography variant="subtitle1" component="div">
                      {`Vous avez déjà un compte ?`}
                      <Button
                        variant="text"
                        color="secondary"
                        onClick={() => navigate("/login/consultant")}
                      >
                        Se connecter
                      </Button>
                    </Typography>
                  </Hidden>
                </Grid>
              </Grid>
            </TabPanel>
          </TabsUnstyled>
        </Grid>
        <Copyright />
      </Grid>
    </>
  );
};
