import { ReactElement } from "react";
import Box from "@mui/material/Box";
import { useStateMachine } from "little-state-machine";
import { Crisp } from "crisp-sdk-web";

import { Topbar, Sidepanel } from "../Components";
import { Main } from "../Theme";

type MainLayoutProps = {
  children: ReactElement;
};

export const MainLayout = ({ children }: MainLayoutProps) => {
  const {
    state: { screen },
  } = useStateMachine();

  if (process.env.REACT_APP_CRISP_WEBSITE_ID) {
    Crisp.configure(process.env.REACT_APP_CRISP_WEBSITE_ID);
  } else {
    console.warn("Crisp Website ID not found");
  }

  return (
    <Box sx={{ display: "flex", minHeight: "100vh" }}>
      <Topbar></Topbar>
      <Sidepanel></Sidepanel>
      {/* Drawer here */}
      <Main open={screen.isSidePanelOpen}>{children}</Main>
    </Box>
  );
};
