import { Fragment, useEffect, useState } from "react";
import {
  credentialsValidation,
  credentialsValidationWithConsent,
} from "./regular.validation";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import ArrowBack from "@mui/icons-material/ArrowBack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import InputAdornment from "@mui/material/InputAdornment";
import EmailOutlined from "@mui/icons-material/EmailOutlined";
import { TAccountCreateData } from "../../../../Types";
import { PasswordTextField } from "../../../../Theme";
import { useStateMachine } from "little-state-machine";
import { updateLoginState } from "../../../../Stores/Login";
import { IAccountCreateFormsCredentialsProps } from "../interfaces.form";
import { Box, Checkbox, Stack, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Link, useSearchParams } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) => ({
  actionButton: {
    margin: theme.spacing(1, 0) + " !important",
  },
}));

export const CredentialsForm = ({
  setCurrentStep,
  handleSignup,
  isBeneficiaryInvited = false,
}: IAccountCreateFormsCredentialsProps) => {
  const [isChecked, setIsChecked] = useState(false);
  const [showConsentText, setShowConsentText] = useState(false);
  const [isAcceptButtonEnabled, setIsAcceptButtonEnabled] = useState(false);
  const classes = useStyles();
  const {
    state: {
      loginSession: { createAccountValues },
    },
    actions,
  } = useStateMachine({ updateLoginState });
  const { register, formState, getValues, control, handleSubmit, setValue } =
    useForm<Partial<TAccountCreateData>>({
      resolver: yupResolver(
        isBeneficiaryInvited
          ? credentialsValidationWithConsent
          : credentialsValidation
      ),
      mode: "onChange",
    });
  const { isValid, errors } = formState;
  const [searchParams] = useSearchParams();

  const email = searchParams.get("email");

  const handleCheck = () => {
    setIsChecked(true);
    setValue("consent", true);
  };

  const onBlurSaveMail = () => {
    actions.updateLoginState({
      createAccountValues: {
        email: getValues("email"),
      },
    });
  };

  const onSubmit = (data: Partial<TAccountCreateData>) => {
    actions.updateLoginState({
      createAccountValues: {
        email: getValues("email"),
      },
    });
    const formData: Partial<TAccountCreateData> = {
      ...data,
      ...createAccountValues,
      // isLocked: createAccountValues.isConsultant,
      // isInvited: createAccountValues.isConsultant,
    };
    handleSignup(formData);
  };

  useEffect(() => {
    if (showConsentText) {
      const box = document.getElementById("consentBox");

      if (box) {
        box.addEventListener("scroll", () => {
          const isBottom =
            box.scrollHeight - box.clientHeight <= box.scrollTop + 1;

          if (isBottom) {
            setIsAcceptButtonEnabled(true);
            box.removeEventListener("scroll", () => {});
          }
        });
      }
    }
  }, [showConsentText]);

  if (showConsentText) {
    return (
      <Stack
        direction="column"
        gap={2}
        justifyContent="space-between"
        maxHeight={400}
      >
        <Typography fontStyle='italic' textAlign='center'>
          Veuillez lire attentivement le texte ci-dessous et cliquer sur le bouton pour continuer :
        </Typography>

        <Box
          id="consentBox"
          height="100%"
          sx={{
            overflowY: "auto",
            backgroundColor: "primary.light",
            borderRadius: 1,
          }}
        >
          <Stack
            sx={{
              display: "flex",
              flexDirection: "column",
              scrollbarWidth: "thin",
              textAlign: "justify",
              p: 1,
              paddingRight: 2,
              gap: 2,
            }}
          >
            <Typography>
              Par le présent consentement, j’accepte de créer mon compte
              bénéficiaire Sam.i pour bénéficier d’un accompagnement dans le
              cadre de mon parcours de maintien en emploi.
            </Typography>

            <Typography>
              Ce parcours numérique me permettra de faire appel à tous les
              intervenants et/ou partenaires dont les compétences et/ou les
              champs d’actions pourront permettre de favoriser mon maintien en
              emploi.
            </Typography>

            <Typography>
              Dans ce but, toutes les informations non médicales pertinentes de
              mon parcours pourront être renseignées et partagées aux
              intervenant et/ou partenaires compétents, via mon parcours
              bénéficiaire sur la plateforme Sam.i.
            </Typography>

            <Typography>
              Conformément à l’Article 32 de la loi Informatique et Libertés,
              ainsi qu'aux articles 13 à 20 du Règlement Général de Protection
              des Données (RGPD) du 27.04.2016, j'ai un droit d'accès complet et
              sans réserve sur les informations contenues dans mon parcours
              bénéficiaire sur la plateforme Sam.i et celles qui sont
              renseignées par les différents intervenants et/ou partenaires du
              maintien en emploi et de la prévention de la désinsertion
              professionnelle.
            </Typography>

            <Typography>
              Je me réserve le droit, à tout moment et sans justificatif, de
              mettre fin à toutes ou parties des démarches en cours et/ou
              initiées par les intervenants et/ou partenaire du maintien en
              emploi, renseignées sur mon parcours bénéficiaire sur la
              plateforme Sam.i. Dans ce cadre, je me rapproche du support de la
              plateforme Sam.i en adressant un mail à :
            </Typography>
              <Link
                to="#"
                onClick={(e) => {
                  e.preventDefault();
                  window.location.href = "mailto:support@tech4work.fr";
                }}
              >
                <Typography fontWeight='bold' fontStyle='italic'>support@tech4work.fr</Typography>
              </Link>
          </Stack>
        </Box>

        <Button
          variant="contained"
          color="primary"
          disabled={!isAcceptButtonEnabled}
          onClick={() => {
            handleCheck();
            setShowConsentText(false);
          }}
        >
          J'accepte
        </Button>
      </Stack>
    );
  }

  return (
    <Fragment>
      <Grid container alignItems="center" gap={2}>
        <IconButton onClick={() => setCurrentStep(1)}>
          <ArrowBack />
        </IconButton>
        <Typography variant="subtitle1">Retour</Typography>
      </Grid>
      <TextField
        {...register("email")}
        defaultValue={createAccountValues.email || email || ""}
        error={errors.email ? true : false}
        required
        autoFocus
        type="email"
        color="primary"
        placeholder="Email"
        onBlur={onBlurSaveMail}
        helperText={errors.email ? errors.email.message : ""}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <EmailOutlined />
            </InputAdornment>
          ),
        }}
      ></TextField>
      <Controller
        name="password"
        control={control}
        rules={{ required: true }}
        defaultValue=""
        render={({ field }) => (
          <PasswordTextField
            {...field}
            errorText={errors.password ? errors.password.message : ""}
            error={errors.password ? true : false}
          />
        )}
      />
      <Controller
        name="passwordConfirmation"
        control={control}
        rules={{ required: true }}
        defaultValue=""
        render={({ field }) => (
          <PasswordTextField
            {...field}
            // onChange={(e) => setPasswordConfirmation(e.currentTarget.value)}
            errorText={
              errors.passwordConfirmation
                ? errors.passwordConfirmation.message
                : ""
            }
            error={errors.passwordConfirmation ? true : false}
          />
        )}
      />

      {isBeneficiaryInvited && (
        <Stack direction="row" alignItems="center">
          <Checkbox
            {...register("consent")}
            checked={isChecked}
            onClick={() => {
              setIsChecked(isChecked)
              setValue("consent", isChecked);
            }}
            color="primary"
            required
          />

          <Typography
            sx={{
              textDecoration: "underline",
              ":hover": {
                cursor: "pointer",
                color: "blue",
              },
            }}
            onClick={() => {
              setShowConsentText(!showConsentText);
            }}
          >
            Consentement éclairé (requis)
          </Typography>
        </Stack>
      )}

      <Typography variant="subtitle2" component="div">
        En cliquant sur le bouton ci-dessous, j&apos;accepte les{" "}
        <Link to="/terms/terms-of-service">
          Conditions Générales d&apos;Utilisation
        </Link>
        &nbsp;ainsi que la{" "}
        <Link to="/terms/privacy-policy">Politique de Confidentialité</Link> de
        la plateforme.
      </Typography>
      <Button
        className={classes.actionButton}
        disabled={isBeneficiaryInvited ? (!isValid || !isChecked) : !isValid}
        variant="contained"
        color="primary"
        onClick={handleSubmit(onSubmit)}
      >
        Créer mon compte
      </Button>
    </Fragment>
  );
};
